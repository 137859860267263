<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>

    <div id="app-content">
      <div class="search-box">
        <van-search v-model="listquary.keywords" shape="round" background="transparent" placeholder="请输入搜索关键词"
          @search="searchShop()" />
      </div>

      <div>
        <FoodInfo :data="item" v-for="(item, index) in footlist" :key="index" />
      </div>
      <div class="page-item load-box" v-if="loading">
        <i class="el-icon-loading"></i>
        <span>请稍等</span>
      </div>
      <el-empty :description="remsg" v-if="remsg" :image="emptyimg" :image-size="100"></el-empty>
      <div class="load-text" v-if="footlist.length">{{ loadtext }}</div>
    </div>
  </div>
</template>

<script>
import { getlist } from '../../api'
import FoodInfo from '../../components/FoodInfo.vue'
import { getScroll, getScrolltop } from '../../utils/utils'
import { mapState } from 'vuex'

export default {
  name: 'ShopList',
  components: { FoodInfo },
  data() {
    return {
      footlist: [],
      listquary: {
        trxCode: 'MS101',
        agentId: localStorage.getItem('agent'),
        page: 1,
        pageSize: 30,
        lng: '116.40717',
        lat: '39.90469',
        distance: '15000',
        keywords: '',
      },
      pageFlag: false, //分页是否完成
      loadtext: '上拉加载',
      remsg: '', //提示信息
      loading: true,
      emptyimg: require('../../assets/home/notshop.png'), //空图片
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'location', 'address', 'versions']),
  },
  watch: {
    address() {
      this.getShopList()
    },
  },
  mounted() {
    if (this.address) {
      this.getShopList()
    }
    window.addEventListener('scroll', this.scrollLister)
    // this.getClassifyList()
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollLister)
  },
  methods: {
    // 搜索商家
    searchShop() {
      this.footlist = []
      this.listquary.page = 1
      this.getShopList()
    },
    // 获取商家列表
    getShopList() {
      this.listquary.lng = this.location.longitude
      this.listquary.lat = this.location.latitude
      getlist(this.listquary).then((res) => {
        this.loading = false
        if (res.foodGoodList) {
          res.foodGoodList.forEach((item) => {
            this.footlist.push(item)
          })
          if (res.foodGoodList.length < 10) {
            this.listquary.page += 1
            this.getShopList()
          }
        } else if (res.rspCode === '0020') {
          // 无商品 继续查询
          this.listquary.page += 1
          this.getShopList()
        } else if (res.rspCode === '0019') {
          // 分页结束
          this.pageFlag = true
          if (this.listquary.page === 1) {
            this.remsg = res.rspMsg
          } else {
            this.loadtext = '没有更多了'
          }
        }
      })
    },
    // 获取分类
    getClassifyList() {
      let params = {
        trxCode: 'MS905',
      }
      getlist(params).then((res) => {
        // console.log(res)
      })
    },
    scrollLister() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      var hegit = getScrolltop()
      var scroll = getScroll()
      if (hegit >= scroll) {
        if (this.pageFlag === false) {
          this.listquary.page = this.listquary.page + 1
          this.getShopList()
        }
      }
    },
  },
}
</script>

<style lang='less' scoped>
.load-text {
  margin: 4vw 0;
  text-align: center;
  font-size: 3.8vw;
  color: #999999;
}
.foodlist {
  min-height: 80vh;
}
</style>